
.title
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    padding: 1rem 0

.margin-loading
    margin-bottom:  1000px

.no-campaign
  position block
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  flex-direction: column
  margin-bottom:  500px
