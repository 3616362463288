
.btn-color
  border-radius: 50px
  box-shadow: 0 0 2px 1.5px white;
  border: 3px solid white;
  display: flex
  align-items: center
  justify-content: center
  box-shadow: inset 0 2px 3px 1px rgba(0,0,0,0.1);
  transition: 0.2s ease-in-out;
  position relative
  //@media small
     //margin: 0 auto;
  @media (hover: hover) and (pointer: fine)
    &:hover:not(.disabled):not(.select)
      // border-color: transparent;
      // box-shadow: 0 0 0 1px $color-bnn-border;
      cursor: pointer
      transition: 0.2s ease-in-out;
  img
    width: 105%
    height: 105%
    position absolute
    top 50%
    left 50%
    transform: translate(-50%, -50%)
.btn-color.select
     // outline: 1.5px solid ;
     // outline-offset: 4px;
     // box-shadow: 0 0 2px 1.5px $color-bnn-border-2;
     box-shadow: 0 0 0 2px $color-border-btn-spec;
     border: 3px solid #FFF;

.btn-color.disabled:not(.select)
    box-shadow: none
    .sold-out
        color: red
        font-weight: bold
        font-size: 0.6rem
        text-shadow: 0 0 2px #fff;
