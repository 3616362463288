
.bg-green
    background-color: $color-green-dark
    color: #fff
.bg-red
    background-color: $color-bnn-red
    color #000

table
    width 100%
    border: none !important;
    border-collapse:collapse;


tr:nth-child(odd) {
  background-color: #FAFAFA;
}

.bg-fafafa
    background-color: #FAFAFA

.border-top
    border-top 1px solid #E3E3E3


thead tr
    // position: sticky;
    top: 0;
    z-index 100


th
    padding: 1rem 1rem
    background: white;
td
    padding: 1rem
    &:first-child
       vertical-align: top

.status
    width: auto
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    font-size: 0.7rem

.layout-matrix
    display: flex
    justify-content: center
    align-items: center
    width 100%
    height 100%

.is-desktop
    display: block
    width 100%
    @media tablet
        display: none
.is-tablet
    display: none
    @media tablet
        width 100%
        display: block

.btn-cancel
  width: 80px
  font-weight: bold !important
  font-size: 0.875rem !important
  padding: 18.5px 0 !important
  border-color: $color-secondary !important
  padding: 0 2rem

.text-cancel
  color: $color-secondary-text

.card-qrcode
  background white
  border-radius: 8px;
  border: 1px solid #00000000;
  padding: 1rem
  display: flex
  justify-content: center

.action-layout
    width: 200px
    display: flex
    justify-content: center
    padding-bottom: 0.5rem

.campaign-text
    font-size 0.875rem
    font-weight: bold
    color: $color-bnn-gray-medium-2
    line-height: 0.375rem

.color-content
  display: flex;
  gap: 16px 24px;
  // grid-template-columns: repeat(6, 1fr);

.overflow
    overflow: auto

.text-span-receive
  color: #01B13D
  font-size: 0.875rem
  text-align: center
  font-weight: bold

.model-stock-container
  display: grid
  grid-template-columns: repeat(5, 1fr)
  gap: 0.75rem

  @media small
    grid-template-columns: repeat(2, 1fr)
    gap: 0.75rem 0.5rem

.model-select-color {
  margin-top: 1rem
  background: #fff
  border-radius: 8px
  padding: 0 1rem
  font-size: 0.875rem
  width 100%
  border: 1px solid $color-bnn-gray-light
}

.model-select-color-not-active {
  margin-top: 1rem
  background: $color-bnn-red
  color: #fff
  border-radius: 8px
  padding:0.5rem 1rem
  font-size: 0.875rem
  width 100%
}
.wrap-btn-verify
  display: flex
  justify-content: flex-end
  gap: 12px
  padding-top: 12px
.btn-verify-text
  color: white !important

.action-container{
  display: flex
  flex-wrap: wrap;
  justify-content: center
  gap: 12px
  > div {
      width: 100%
  }

  .btn-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .status{
    // width: 100%
  }

  @media screen and (min-width: 600px){
	.status{
	 // width: fit-content !important
	}
  }
}
.status
	.v-icon
		font-size: 16px

.slide-down-enter-active,
.slide-down-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slide-down-enter-to,
.slide-down-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slide-down-enter-from,
.slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
}

::v-deep .v-btn.btn-action{
	padding: 12px;
	// height: fit-content;

	.v-btn__content {
  		flex-wrap: wrap !important;
	}
}

::v-deep .v-btn.status{
	padding: 8px 16px !important;
}

.button-container{
    display: grid
    flex-wrap: wrap;
    justify-content: center
    gap: 12px
    grid-template-columns: repeat(3, minmax(100px, 1fr))
}

.product-name-qr{
    font-weight: bold
    word-break: break-word
}

.select-color-message {
    max-width: 100%

    @media screen and (min-width: 1024px) {
        max-width: 40%
    }
}
